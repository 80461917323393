import React from "react";

import { BlogAboutUsSchema } from "@/schema";
import { Typography } from "@/uikit/Typography";
import { renderActions } from "@/blocks/schema-mappers";
import { RemoteImage } from "@/uikit/remote-image";

import {
  aboutUsWrapperStyle,
  aboutUsContainerStyle,
  imgStyle,
  titleStyle,
  textStyle,
  actionsWrapperStyle,
} from "./aboutUs.css";
import { BlockContainer } from "@/blocks/base/block-container";

export const BlogAboutUs = (props: BlogAboutUsSchema) => {
  return (
    <div className={aboutUsWrapperStyle}>
      <BlockContainer
        defaultTheme="moondusk"
        settings={{ searchable: true, paddingTop: "large", paddingBottom: "large", _type: "blockSettings" }}
        className={aboutUsContainerStyle}
      >
        <Typography.title className={titleStyle}>{props.title}</Typography.title>
        <RemoteImage className={imgStyle} image={props.heroImage} />
        <Typography settings={{ tag: "p" }} className={textStyle}>
          {props.text}
        </Typography>
        {props.actions && <div className={actionsWrapperStyle}>{renderActions(props.actions)}</div>}
      </BlockContainer>
    </div>
  );
};

export default BlogAboutUs;
